.marlon {
  display: flex;
  list-style: none
} 
.marlon > li > a {
  background-color: transparent !important;
  color: purple;
  font-size: 16pt;
  border-color: none !important;
  z-index: auto !important;
}
.marlon,
.marlon > li,
.marlon > li > a {
  background-color: transparent !important;
  border: none !important;
  z-index: auto !important;
  color: black
}

.marlon > li > a:focus,
.marlon > li > a:hover,
.marlon > li > span:focus,
.marlon > li > span:hover {
  color: black !important;
  background-color: transparent !important;
  border-color: none !important;
  /* font-weight: bolder; */
  font-size: 15pt;
  z-index: auto !important;
}

.marlon > .active > span {
  color: black !important;
  background-color: transparent !important;
  border: none !important;
  font-weight: bolder;
  font-size: 16pt;
  z-index: auto !important;
}

.marlon > .active > a:hover {
  background-color: transparent !important;
  border: none !important;
  font-size: 16pt;
  z-index: auto !important;
}

.marlon > li > a,
.marlon > li > span {
  color: black !important;
  font-size: 16pt;
  z-index: auto !important;
}

.p-next-arrow > a {
  font-size: 16pt !important;
  margin-bottom: 10px;
  padding-bottom: 10px !important;
  z-index: auto !important;
}

.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
  font-size: 16pt;
  z-index: auto !important;
}
.marlon > li > a:focus, .marlon > li > a:hover, .marlon > li > span:focus, .marlon > li > span:hover, .marlon > .active > span, .marlon > li > a, .marlon > li > span {
  color: black !important;
}

.pagination > li > a {
  color: black !important
}