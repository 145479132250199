.content-img-size {
  max-height: 40vh;
  min-height: 40vh;
}

.video-img-size {
  max-height: 40vh;
  min-height: 40vh;
}
.video-play-size {
  max-height: 80vh;
  /* min-height: 80vh; */
}

.pos-relative {
  position: relative;
}

@media all and (max-width: 995px) {
  .news-img-jesse {
    min-height: 40vh;
    max-height: 40vh;
  }
  .col-music {
    height: 12vh;
    max-height: 12vh;
  }
  .nameAuthor {
    position: absolute;
    left: 168px;
  }
}

@media all and (min-width: 995px) {
  .video-footer {
    margin-top: 3.5rem;
  }
  .news-img-jesse {
    min-height: 22vh;
    max-height: 22vh;
  }
  .nameAuthor {
    position: absolute;
    left: 17vw;
  }
}

.music-artist-name {
  position: absolute;
  right: 3%;
  bottom: 25%;
}

.music-artist-name span,
.music-song-title {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 10pt;
}
.music-song-title {
  font-weight: bold;
}

.player-box {
  width: 100%;
  height: 6vh;
  background-color: #f1c477;
  padding-left: 10px;
  padding-right: 10px;
  /* padding-bottom: 5px; */
}

.pointer {
  cursor: pointer;
}

.box-yellow {
  background-color: #eabf74;
  border-color: #eabf74;
}
.box-orange {
  background-color: #df544f;
  border-color: #df544f;
}
.box-purple {
  background-color: #a43c59;
  border-color: #a43c59;
}
.box-blue {
  background-color: #101026;
  border-color: #101026;
}
