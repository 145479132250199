.header {
  background-color: #e5e5e5;
  max-height: 150px;
}

.header .row {
  min-height: 140px;
  max-height: 140px;
}

.header,
.header .container,
.header .container .icon-box,
.header .container .login-box {
  min-height: 150px;
}

.header .container .login-box {
  font-family: "Montserrat", sans-serif;
  font-size: 10pt;
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 40px;
}

.main-content,
.footer,
.most-listened,
.secondary-links {
  min-height: 600px;
}

.carousel {
  min-height: 44vh;
}

.top-rated,
.news-sections {
  background-color: #7b2a7c;
  min-height: 600px;
}
.single-news {
  background-color: #e5e5e5;
  min-height: 600px;
}
.most-listened,
.secondary-links {
  background-color: #ef5953;
}
/* style={{width: "165px", height: "105.330px", backgroundImage: `url("${url}${song.image}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} */
/* .main-content {
  
  background-image: url("../../../images/final_elegido.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  
} */
/* .main-content {
  
  background-image: url("../../../images/final_elegido.png");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
 
} */

/* @media all and (max-width: 995px) { */
@media all and (orientation: portrait) {
  .main-content {
    
    background-image: url("../../../images/Untitled.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }
}

/* @media all and (min-width: 995px) { */
  @media all and (orientation: landscape) {
  .main-content {
    
    background-image: url("../../../images/final_elegido.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
   
  }
  
  
  
  
}




.main-content .social-media {
  padding-top: 40px;
}

.footer {
  background-color: #b43e62;
  background-color: #000000;
}

.navbar-light .navbar-toggler.site {
  color: transparent;
  border-color: transparent;
}

.navbar.site {
  padding: 0px;
  min-height: 150px !important;
}

.navbar-nav.site {
  align-items: flex-end;
  margin-right: 0.5rem;
}

span.input-group-text {
  border: 0px !important;
  border-top-right-radius: 0.7rem !important;
  border-bottom-right-radius: 0.7rem !important;
  background-color: #ffff;
}

#inlineFormInputGroup {
  border-top-left-radius: 0.7rem !important;
  border-bottom-left-radius: 0.7rem !important;
  border: 0px;
}

#responsive-navbar-nav {
  padding-top: 5% !important;
}

@media all and (max-width: 900px) {
  .search-input,
  .site.navbar-nav {
    display: none;
  }
  .min-menu {
    display: block !important;
  }
  .social-media-auth {
    right: 25vw;
  }
  .section-title span {
    font-size: 7vw;
  }
  .most-ranked {
    height: 256px;
  }
  /* .col-music {
    min-height: 12vh;
  }
  .ele-music {
    max-width: 25vw;
    min-height: 12vh;
    width: 30vw;
  }
  .img-music {
    position: absolute;
    top: 37%;
    left: 8%;
  } */
  .col-music {
    height: 12vh;
    max-height: 12vh;
  }
  .img-comtainer-music {
    max-height: 12vh;
    min-height: 12vh;
  }
  .song-img-music {
    max-width: 25vw;
    height: auto;
    min-height: 12vh;
    width: 25vw;
  }
  .txt-music {
    font-size: 10pt;
  }
  .subtxt-music {
    font-size: 8pt;
  }
  .main-not-auth-img {
    min-height: 60vh
  }
}
@media all and (min-width: 995px) {
  .min-menu {
    display: none !important;
  }
  .social-media-auth {
    right: 5vw;
  }
  .section-title span {
    font-size: 3vw;
  }
  .most-ranked {
    height: 766px;
  }
  /* .ele-music { */
  /* max-width: 15vw; */
  /* min-height: 15vh; */
  /* width: 15vw; */
  /* } */
  /* .col-music {
    min-height: 15vh;
  }
  .img-music {
    position: absolute;
    top: 48%;
    left: 7%;
  } */
  .col-music {
    height: 15vh;
    max-height: 15vh;
  }
  .song-img-music {
    max-width: 15vw;
    height: auto;
    min-height: 15vh;
    width: 15vw;
  }
  .img-comtainer-music {
    max-height: 15vh;
    min-height: 15vh;
  }
  .txt-music {
    font-size: 14pt;
  }
  .subtxt-music {
    font-size: 12pt;
  }
  .main-not-auth-img {
    min-height: 80vh
  }
}

.search-input {
  max-height: auto;
  min-height: auto;
  padding-top: 0%;
  position: absolute;
  left: 83%;
  top: 2.5vh;
  width: 20%;
}

.nav-items.site {
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  font-weight: bold;
}

.ml-3rem {
  margin-left: 3rem;
}
.ml-4rem {
  margin-left: 4rem;
}

.orange {
  color: #ef5953 !important;
}
.purple {
  color: #7b2a7c !important;
}
.black {
  color: #13133c !important;
}
.carousel-indicators li {
  background-color: transparent !important;
  background-image: url("../../../images/carousel/A.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;

  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  /* background-color: #fff; */
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  /* opacity: 0.5; */
  transition: opacity 0.6s ease;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  /* bottom: 40px; */
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.social-media-auth {
  position: absolute;
  top: 40px;
  z-index: 1000;
}

video:focus {
  outline: none;
}

.more-button .details {
  border-radius: 15px !important;
  width: 120px;
  height: 30px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 10pt;
  line-height: 1.2;
  top: 30vw;
  right: 6% !important;
  font-weight: bold;
}

.tag-votes {
  height: 7%;
  width: 30%;
  position: absolute;
  top: 1vh;
  right: 15.5px;
  background-color: #ffffff80;
  color: #ffffff;
  padding-left: 1%;
  font-size: 8pt;
  font-weight: bold;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.tag-listened {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 1vh;
  right: 30px;
  background-color: #ffffff80;
  color: #ffffff;
  font-size: 24pt;
  font-weight: bolder;
  border-radius: 50% !important;
}

.section-title {
  margin-top: 25px;
}

.section-title img {
  margin-bottom: 1.7vw;
}

.section-title span {
  font-family: "Montserrat", sans-serif;
  /* font-size: 5.2vh; */
  font-weight: "bold";
  color: white;
}

.mv-artist-name a {
  font-family: "Montserrat", sans-serif;
  font-size: 10pt;
  font-weight: bold;
  color: white;
  margin: 0;
  padding: 0;
}

.mv-artist-content-name {
  font-family: "Montserrat", sans-serif;
  font-size: 8pt;
  margin: 0;
  padding: 0;
  color: white;
  margin-bottom: 40px;
}

.c-1 {
  background-color: #ef5953;
}
.c-2 {
  background-color: #7b2a7c;
}
.c-3 {
  background-color: #bb3b5d;
}
.c-4 {
  background-color: #ef534e;
}
.c-5 {
  background-color: #f2b66d;
}
.img-listened img {
  max-height: 150px;
  min-height: 150px;
}

.progressb {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;
  margin-left: 10px;
  margin-right: 20px;
}

#prgbar {
  /* background: transparent; */
  width: 100px;
}

.vlme {
  display: flex;
  align-content: center;
  overflow: hidden;
  width: 25px;
  transition: all 500ms;
  position: absolute;
  left: 0;
}

.vlme:hover {
  width: 80px;
}

.volum {
  padding: 10px;
}

#volBar {
  padding: 0;
  margin: 0;
  width: 40px;
  background: transparent;
}

#volBar::-moz-range-thumb {
  height: 10px;
  width: 3px;
  background: #2a6586;
  border-radius: 5px;
  cursor: pointer;
}

.my-facebook-button-class i {
  font-family: FontAwesome !important;
}
