.color-1o {
  background-color: #df544f;
}
.color-2pl {
  background-color: #a63958;
}
.color-3pb {
  background-color: #732a67;
}
.color-4bb {
  background-color: #1c1126;
}
.color-5ol {
  background-color: #f2b66d;
}

@media all and (max-width: 995px) {
  .art-picture {
    height: 280px !important;
  }
}
@media all and (min-width: 995px) {
  .art-picture {
    height: 380px !important;
  }
}
