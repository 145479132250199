h4 {
  font-weight: 400;
}
button.login {
  font-weight: 500 !important;
  background-color: #2a3542 !important;
  border-color: #2a3542 !important;
}
.toast.login-toast {
  min-width: 30%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.48);
  z-index: 1000;
}
.toast-body.login-toast {
  padding: 2rem;
  padding-top: 1rem;
}
.login-container {
  height: 100vh;
}
.login-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container::before {
  /* content: '';
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    background: #008dff;
    background: #2780E3;
    background: #3f8ee6;
    /* background: #373a3c; */
  /*border-radius: 50%;
    transform-origin: bottom;
    transform: translate(-50%) scale(2); */
}
label.recovery {
  margin-left: 0.2rem;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}

label.recovery:hover {
  color: #122f54 !important;
}
.login-icon {
  font-size: 5vh;
}
/* .login-btn-icon {
    font-size: 3vh;
} */
.login-help-text {
  font-size: 0.98rem;
  text-align: center;
  color: #8492a6;
}
