* {
  border-radius: 0px !important;
  font-family: "Montserrat", sans-serif !important;
}
img.img-thumbnail {
  border-radius: 50% !important;
}

button.radius {
  border-radius: 16px !important;
}

.badge-pill {
  border-radius: 10rem !important;
}

.input-group-append span.input-group-text {
  background-color: #6c757d !important;
  border: 1px solid #6c757d !important;
}
