.my-facebook-button-class {
  /* background-image: url("../../../images/FACE.png") !important; */
  background-color: #2953a5 !important;
  border-radius: 50px !important;
  /* border-color: #2953a5 !important; */
  border: none;
  height: 43px;
  width: 43px;
  color: white;
  font-size: 16pt;
}
